@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;1,200;1,300;1,400;1,600;1,700&display=swap");

@font-face {
  font-family: monbold;
  src: url(../fonts/Montserrat-SemiBold.ttf);
  font-weight: bold;
}

@font-face {

  font-family: monreg;
  src: url(../fonts/Montserrat-Regular.ttf);
  font-weight: bold;

}

@font-face {

  font-family: monmed;
  src: url(../fonts/Montserrat-Medium.ttf);
  font-weight: bold;

}

@font-face {
  font-family: inter;
  src: url(../fonts/Inter-Medium.ttf);
  font-weight: bold;
}

@font-face {
  font-family: clashbold;
  src: url(../fonts/ClashDisplay-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: clashreg;
  src: url(../fonts/ClashDisplay-Regular.otf);
  font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
  transition: 0.5s ease-in !important;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth !important;
  }
}

@media print {
  :root {
    display: none !important;
    pointer-events: none !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


body {
  background-color: transparent !important;
  overscroll-behavior-y: contain;
  font-family: monreg, sans-serif !important;
  background-image: url("../img/dd.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.fa-beat{

  animation-duration: 10s !important;
}

.Toastify__toast {

  min-height: 1rem !important;
  padding: 3px !important;
  height: 5rem !important;
}

.Toastify__toast-body > div:last-child {

  text-align: center !important;

}

.Toastify__close-button > svg {

  display: none !important;

}

.Toastify__toast-theme--dark {

  border: none !important;
  border-radius: 1.5rem !important;
  background-color: #FBCF00 !important;
  color: #000 !important;
  margin: 0.5rem !important;
}

.Toastify__toast-theme--info {

  border: none !important;
  border-radius: 1.5rem !important;
  background-color: #FBCF00 !important;
  color: #000 !important;
  margin: 0.5rem !important;
}

.Toastify__toast-theme--danger {

  border: none !important;
  border-radius: 1.5rem !important;
  background-color: red !important;
  color: #fff !important;
  margin: 0.5rem !important;
}


.home h5, h6 {

  font-family: monbold, sans-serif !important;
  color: #A020F0 !important;
}

.home .navlink {
  background-color: #E2BAFA !important;
  border-radius: 3rem !important;
}

.home .navlink .active {

  background-color: #A020F0 !important;
  border-radius: 2rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  color: #fff;

}

.home .abtnavlink {
  background-color: #43B671 !important;
  border-radius: 3rem !important;
  color: #fff !important;
}

.home .abtnavlink .abtactive {

  background-color: #fff !important;
  border-radius: 2rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  color: #000;

}

.home h1 {

  font-family: clashbold, sans-serif;
  word-spacing: 0.7rem !important;
}

.home span {
  color: #A020F0 !important;
}

.home .lead p {

  font-size: 1.84rem !important;
}

.home .green {

  color: #43B671 !important;
}

.home .bg-success {

  background-color: #43B671 !important;
  font-family: clashreg, sans-serif;
}

.navvb {

  font-family: monbold, sans-serif;
  background-color: #fff !important;
  border: 2px solid #A020F0;
  box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 50px 50px;
  left: calc(50% - 428px/2);
  
}


.navvb .active{

  font-family: monbold, sans-serif;
  box-shadow: 0px 20px 40px 10px rgba(0, 0, 0, 0.25);
  
}

.navvb button{

  font-family: monbold, sans-serif;
  background-color: #A020F0 !important;
  color: #fff !important;
  width: 90% !important;
  border-radius: 1rem !important;
}



.features h1 {

  font-family: clashbold, sans-serif;
  word-spacing: 0.5rem !important;
}

.features h1 span {

  color: #43B671 !important;
}

.features p {

  font-family: monreg, sans-serif !important;
}

.features button {

  background-color: #43B671 !important;
  color: #fff !important;
  border-radius: 0.7rem !important;
}

.features .feature-2 {
  margin-top: 8rem !important;
}

.features .feature-2 span {
  color: red !important;
}

.features .feature-2 button {

  background-color: red !important;
  color: #fff !important;
}


.features .feature-3 {
  text-align: center !important;
  justify-content: center !important;
}

.features .feature-3 span {
  color: #FF971D !important;
}

.features .feature-3 button {

  background-color: #FF971D !important;
  color: #000 !important;
}

.features .feature-4 {
  margin-top: 8rem !important;
}

.features .feature-4 span{

  color: #A020F0 !important;
}

.features .feature-4 button {

  background-color: #A020F0 !important;
  color: #fff !important;
}

.features .feature-1 button:hover {

  background-color: transparent !important;
  color: #000 !important;
  border-radius: 0.7rem !important;
  border: 2px solid #43B671 !important;

}

.features .feature-2 button:hover {

  background-color: transparent !important;
  color: #000 !important;
  border-radius: 0.7rem !important;
  border: 2px solid red !important;

}

.features .feature-3 button:hover {

  background-color: transparent !important;
  color: #000 !important;
  border-radius: 0.7rem !important;
  border: 2px solid #FF971D !important;

}

.partners h1 {

  font-family: clashbold, sans-serif;
  word-spacing: 0.3rem !important;

}

.careers h1 {

  font-family: clashbold, sans-serif;
  word-spacing: 0.3rem !important;

}

.careers p {

  font-family: clashreg, sans-serif;
  font-size: 1.6rem !important;
}

.jumbo {
  padding-top: 10rem !important;
  background-color: #E2BAFA !important;
  border-radius: 1.5rem !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.jumbo .green {
  background-color: #43B671 !important;
}

.jumbo .soc {

  font-family: clashreg, sans-serif;
}

.jumbo h3 {

  font-family: clashbold, sans-serif;
  color: #A020F0 !important;
  word-spacing: 0.3rem !important;
}

.jumbo img {

  width: 12rem !important;

}

.footer {

  margin-top: 15rem !important;
}

.footer h3 {

  font-family: clashreg, sans-serif;
  color: #A020F0 !important;
}

.footer h5 {

  font-family: clashreg, sans-serif;
  word-spacing: 0.3rem !important;
}

.footer .log h4 {

  font-family: monbold, sans-serif !important;
  color: #A020F0 !important;
}

/********** RESPONSIVENESS **********/

/* For 480 Resolution - Mobile*/
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* STYLES GO HERE */

  .Toastify__toast-theme--dark {

    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
    
  .Toastify__toast-theme--info {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
  
  .Toastify__toast-theme--danger {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: red !important;
    color: #fff !important;
    margin: 1.2rem !important;
  } 

  .home h1 {

    font-size: calc(1.625rem + 2vw);
  }

  .home .lead {

    font-size: 1rem;
  }

  .home .vh-100 {

    height: 83vh !important;
  }

  .home .playico img{

    width: 13rem !important;

  }

  .features .lead {

    font-size: 1rem;
  }

  .features .dbtn {

    text-align: center !important;
    justify-content: center !important;

  }

  .features, .feature-2, .feature-3 {
    margin-top: 4rem !important;
  }

  .features .feature-3 {
    text-align: left !important;
    justify-content: left !important;
  }

  .partners img {

    width: 6rem !important;
  }

  .careers p {

    font-family: clashreg, sans-serif;
    font-size: 1.2rem !important;
  }

  .jumbo {
    padding-top: 5rem !important;
  }

  .footer .foot p{

    font-size: 0.8rem !important;
  }

  .footer h5 {
    font-size: 1rem !important;
    font-family: clashreg, sans-serif;
  }
  
}

/* For 640 Resolution - mobile */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {

  .Toastify__toast-theme--dark {

    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
    
  .Toastify__toast-theme--info {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: #FBCF00 !important;
    color: #000 !important;
    margin: 1.2rem !important;
  }
  
  .Toastify__toast-theme--danger {
  
    border: none !important;
    border-radius: 1.5rem !important;
    background-color: red !important;
    color: #fff !important;
    margin: 1.2rem !important;
  }

}

/* For 1024 Resolution - laptop 1 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
 
}

/* For 1366 Resolution  -- laptop 2*/
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  /* STYLES GO HERE */
}

/** desktop screen */
@media only screen and (min-width: 1370px) and (max-width: 1605px) {
  /* STYLES GO HERE */
}

/*Ipad Orientation : Landscape */
@media only screen and (orientation: landscape) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
  /* STYLES GO HERE */
}

/*ipad Orientation : Portrait */
@media only screen and (orientation: portrait) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
  /* STYLES GO HERE */
}